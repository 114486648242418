

.rtl {
  .sidebar .nav li.active > a[data-toggle="collapse"]:before,
  .sidebar
  .nav
  li.active
  > a[data-toggle="collapse"]
  + div
  .nav
  .active
  a:before {
    right: -4px;
    left: auto;
  }

  .sidebar .logo a.logo-mini {
    //margin-right: 15px;
    //margin-left: 15px;
  }

  .fixed-plugin {
    border-radius: 0 8px 8px 0;
    left: 0;
    right: unset;

    .dropdown-menu {
      left: 80px !important;
      right: auto !important;

      &:before,
      &:after {
        left: 10px;
        margin-right: auto;
        right: auto;
      }

      &:before {
        right: unset;
        left: -16px;
        border-left: unset;
        border-right: 0.4em solid rgba(0, 0, 0, 0.2);
      }

      &:after {
        right: unset;
        left: -16px;
        border-left: unset;
        border-right: 0.4em solid $black;
      }
    }
  }

  .card-tasks .card-header .title {
    margin-right: 0;
    margin-left: 24px;
  }

  .main-panel {
    overflow-x: hidden !important;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }
}

.promo_code {
  display: inline-block;
  padding: 10px;
  background-color: #7A2B87;
  color: #fff !important;
  border-radius: 8px;
  background-color: #7A2B87;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.map-text {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.map-text-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.secondary-nav {
  display: flex;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .dropdown {
    margin: 5px 0 10px 0;
  }
  .dropdown-toggle:after {
    margin-left: 1em;
    margin-top: 8px;
  }
}

@media (min-width: 768px) {

  .rtl-active .navbar-nav > li {
    float: right;
    display: inline;
  }

  .rtl .search-bar {
    margin-right: 40px;
    padding: 10px 0 0;
  }
}