.sidebar .logo .logo-mini .logo-img {
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: #fff;
  width: 60px;
  height: 60px;
  overflow: hidden;
  vertical-align: middle;
  display: table-cell;
}

.sidebar .logo .logo-mini .logo-img img {
  max-width: 100%;
  max-height: 100%;
}

.logoSidebar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

// .perfect-scrollbar-on {
//   .sidebar {
//     height: 100%;
//   }
// }

@include media-breakpoint-down(sm) {
  .sidebar {
    height: 100%;
  }
}

@include media-breakpoint-down(md) {
  html.nav-open body {
    position: relative;
    overflow-x: hidden;
  }
  html.nav-open .main-panel {
    overflow: auto;
    min-height: unset;
  }
  html.nav-open .wrapper {
    overflow: hidden;
  }
}
